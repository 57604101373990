import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'

export default function FinancialReportPage() {
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <Helmet>
        <title>{`${t("financial_reports")} | CLSA Premium`}</title>
      </Helmet>
      <Banner title={t('financial_reports')} bg={'financial_reports'} />
      <section className='container financial-report'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="2024">
          <Row>
            <Col sm={1}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="2024">2024</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2023">2023</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2022">2022</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2021">2021</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2020">2020</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2019">2019</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2018">2018</Nav.Link>
                </Nav.Item>
                 <Nav.Item>
                  <Nav.Link eventKey="2017">2017</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2016">2016</Nav.Link>
                </Nav.Item>
                 <Nav.Item>
                  <Nav.Link eventKey="2015">2015</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2014">2014</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2013">2013</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={11}>
              <Tab.Content>
              <Tab.Pane eventKey="2024">
                <div class="containerSlider">
                  <div className="bx-wrapper">
                    <div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a
                              href={
                                i18n.language === "en"
                                ? "/documents/ew_06877AR-20240311.pdf"
                                : "/documents/cw_06877AR-20240311.pdf"
                              }
                              target="_blank"
                            >
                              <p className="dt">03.28</p>
                              <hr />
                              <div className="img"><img src='/images/documents/6877_ar23_covi02.jpg'/></div>
                              <p>{`2023 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a
                              href={
                                i18n.language === "en"
                                ? "/documents/ew-06877ESG-20240328.pdf"
                                : "/documents/cw-06877ESG-20240328.pdf"
                              }
                              target="_blank"
                            >
                              <p className="dt">03.28</p>
                              <hr />
                              <div className="img"><img src='/images/documents/6877_ESG23_covi01.jpg'/></div>
                              <p>{`2023 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="2023">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                      <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "en"
                                  ? "/documents/ew_06877AR-13032023.pdf"
                                  : "/documents/cw_06877AR-13032023.pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">04.11</p>
                              <hr />
                              <div className="img"><img src='/images/documents/6877_ar_2023.jpg'/></div>
                              <p>{`2022 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "en"
                                  ? "/documents/ew-06877ESG-03042023.pdf"
                                  : "/documents/cw-06877ESG-03042023.pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">04.11</p>
                              <hr />
                              <div className="img"><img src='/images/documents/6877_esg_2023.jpg'/></div>
                              <p>{`2022 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "en"
                                    ? "/documents/ew_06877IR-24082023.pdf"
                                    : "/documents/cw_06877IR-24082023.pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">08.24</p>
                              <hr />
                              <div className="img"><img src='/images/documents/6877_ir23_covc02.jpg'/></div>
                              <p>{`2023 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
              <Tab.Pane eventKey="2022">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                      <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "en"
                                    ? "/documents/en_Annual_Report-14032022.pdf"
                                    : "/documents/cw_Annual_Report-14032022.pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">03.24</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2022-3.jpg'/></div>
                              <p>{`2021 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "en"
                                    ? "/documents/ew_06877ESG-14042022.pdf"
                                    : "/documents/cw_06877ESG-14042022.pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">04.14</p>
                              <hr />
                              <div className="img"><img src='/images/documents/6877_esg21_covj02.jpg'/></div>
                              <p>{`2021 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "en"
                                    ? "/documents/ew_06877IR-01082022.pdf"
                                    : "/documents/cw_06877IR-01082022.pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">08.11</p>
                              <hr />
                              <div className="img"><img src='/images/documents/6877_ir22_covv03.jpg'/></div>
                              <p>{`2022 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>


              <Tab.Pane eventKey="2021">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >

                      <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "cn" || i18n.language === "tc"
                                    ? "/documents/cir20210823.pdf"
                                    : "/documents/eir20210823.pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">08.23</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2021-8.jpg'/></div>
                              <p>{`2021 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                              <a href="/documents/e6877_210426_ESG.pdf" target="_blank" >
                              <p className="dt">04.28</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2021-4-ESG.jpg'/></div>
                              <p>{`2020 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                              <a
                                href={
                                  i18n.language === "cn"
                                    ? "/documents/2020 Annual Report (Simplified Chinese).pdf"
                                    : i18n.language === "tc"
                                    ? "/documents/2020 Annual Report (Tradictional Chinese).pdf"
                                    : "/documents/2020 Annual Report (English).pdf"
                                }
                                target="_blank"
                              >
                              <p className="dt">04.07</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2021-4.jpg'/></div>
                              <p>{`2020 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2020">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/e6877_200824_ir.pdf" target="_blank">
                              <p className="dt">08.27</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2020-8.jpg'/></div>
                              <p>{`2020 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/e6877_220620_ESG.pdf" target="_blank">
                              <p className="dt">06.23</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2020-6.jpg'/></div>
                              <p>{`2019 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/e6877_200424_ar.pdf" target="_blank">
                              <p className="dt">04.28</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2020-4.jpg'/></div>
                              <p>{`2019 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2019">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/e6877_190815_ir.pdf" target="_blank">
                              <p className="dt">08.21</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2019-8.jpg'/></div>
                              <p>{`2019 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a href="/documents/e6877_2018 ESG.pdf" target="_blank">
                              <p className="dt">06.19</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2019-6.jpg'/></div>
                              <p>{`2018 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a href="/documents/e6877_190318_ar.pdf" target="_blank">
                              <p className="dt">04.26</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2018-3.jpg'/></div>
                              <p>{`2018 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2018">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/e6877_190815_ir.pdf" target="_blank">
                              <p className="dt">08.22</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2018-2.jpg'/></div>
                              <p>{`2018 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/e6877_2018 ESG.pdf" target="_blank">
                              <p className="dt">05.18</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2018-Environment.jpg'/></div>
                              <p>{`2017 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/e6877_190318_ar.pdf" target="_blank">
                              <p className="dt">04.17</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2018-1.jpg'/></div>
                              <p>{`2017 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2017">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/20171113044017191.pdf" target="_blank">
                              <p className="dt">11.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2017-1.jpg'/></div>
                              <p>{`2017 ${t('3rd_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20170810035251402.pdf" target="_blank">
                              <p className="dt">08.10</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2017-2.jpg'/></div>
                              <p>{`2017 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20170621021045988.pdf" target="_blank">
                              <p className="dt">06.20</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2017-3.jpg'/></div>
                              <p>{`2016 ${t('esg')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a href="/documents/20170512045312493.pdf" target="_blank">
                              <p className="dt">05.12</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2017-4.jpg'/></div>
                              <p>{`2016 ${t('1st_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a href="/documents/20170328104354263.pdf" target="_blank">
                              <p className="dt">03.28</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2017-5.jpg'/></div>
                              <p>{`2016 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2016">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/20161111053319129.pdf" target="_blank">
                              <p className="dt">11.11</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2016-1.jpg'/></div>
                              <p>{`2016 ${t('3rd_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20160811044814722.pdf" target="_blank">
                              <p className="dt">08.11</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2016-2.jpg'/></div>
                              <p>{`2016 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a href="/documents/20160513101651283.pdf" target="_blank">
                              <p className="dt">05.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2016-3.jpg'/></div>
                              <p>{`2016 ${t('1st_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a href="/documents/20160330064056848.pdf" target="_blank">
                              <p className="dt">03.30</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2016-4.jpg'/></div>
                              <p>{`2015 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2015">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/20151112042538387.pdf" target="_blank">
                              <p className="dt">11.12</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2015-1.jpg'/></div>
                              <p>{`2015 ${t('3rd_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20150813044916400.pdf" target="_blank">
                              <p className="dt">08.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2015-2.jpg'/></div>
                              <p>{`2015 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20150514054237804.pdf" target="_blank">
                              <p className="dt">05.14</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2015-3.jpg'/></div>
                              <p>{`2015 ${t('1st_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                        <li>
                          <article>
                            <a href="/documents/20150331064122672.pdf" target="_blank">
                              <p className="dt">03.31</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2015-4.jpg'/></div>
                              <p>{`2014 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2014">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/20141113050010238.pdf" target="_blank">
                              <p className="dt">11.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2014-1.jpg'/></div>
                              <p>{`2014 ${t('3rd_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20140813052800878.pdf" target="_blank">
                              <p className="dt">08.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2014-2.jpg'/></div>
                              <p>{`2014 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20140513061829576.pdf" target="_blank">
                              <p className="dt">05.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2014-3.jpg'/></div>
                              <p>{`2014 ${t('1st_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20140328063614434.pdf" target="_blank">
                              <p className="dt">03.28</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2014-4.jpg'/></div>
                              <p>{`2013 ${t('annual_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2013">
                  <div class="containerSlider">
                    <div className="bx-wrapper"><div className="bx-viewport" >
                      <ul className=" photoBox2 slider" >
                        <li>
                          <article>
                            <a href="/documents/20131113061650631.pdf" target="_blank">
                              <p className="dt">11.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2013-1.jpg'/></div>
                              <p>{`2013 ${t('3rd_quarter_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>

                        <li>
                          <article>
                            <a href="/documents/20130813085240173.pdf" target="_blank">
                              <p className="dt">08.13</p>
                              <hr />
                              <div className="img"><img src='/images/documents/2013-2.jpg'/></div>
                              <p>{`2013 ${t('interim_report')}`}</p>
                              <div className="hover"></div>
                            </a>
                          </article>
                        </li>
                      </ul></div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </section>
    </div>
  );
}