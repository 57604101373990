/** @format */

import $ from "jquery";
import React, { useCallback, useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

export default function Header() {
  const { t, i18n } = useTranslation();
  const { replace: replaceHistory } = useHistory();
  const { pathname: path } = useLocation();
  // const [lang, setLang] = useState(
  //   i18n.language === "cn"
  //     ? "简体中文"
  //     : i18n.language === "tc"
  //     ? "繁体中文"
  //     : "English"
  // );
  const [textHover, setTextHover] = useState("");

  let isSubsidiary = false;
  let subsidiary = "";
  // const path = props.history.location.pathname
  if (path.includes("hk")) {
    isSubsidiary = true;
    subsidiary = "hk";
  } else if (path.includes("au")) {
    isSubsidiary = true;
    subsidiary = "au";
  } else if (path.includes("nz")) {
    isSubsidiary = true;
    subsidiary = "nz";
  } else if (path.includes("bullion")) {
    isSubsidiary = true;
    subsidiary = "bullion";
  }

  const [show, setShow] = useState("");
  const showDropdown = (e) => {
    if ($(e.target).hasClass("nav-link")) {
      setTextHover(e.target.text);
      setShow(e.target.text);
    }
  };
  const hideDropdown = () => {
    setShow("");
  };
  const toggleDropdown = (e) => {
    if (!e) {
      hideDropdown();
    }
  };
  // useEffect(()=>{

  //   function handleScroll(){
  //     if($(window).scrollTop() < 200){
  //       setBgColor('none')
  //     }else {
  //       setBgColor('white')
  //     }
  //   }
  //   setLang(i18n.language === 'en' ? 'English' : i18n.language === 'cn' ? '简体中文' : '繁体中文')
  //   window.addEventListener('scroll', handleScroll)
  //   return function cleanup(){
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // })
  const changeLanguage = useCallback(
    (lng) => {
      i18n.changeLanguage(lng);
      // switch (lng) {
      //   case "en":
      //     setLang("English");
      //     break;
      //   case "cn":
      //     setLang("简体中文");
      //     break;
      //   case "tc":
      //     setLang("繁体中文");
      //     break;
      //   default:
      //     setLang("English");
      // }

      if (isSubsidiary) {
        replaceHistory(
          /\/(cn|en|tc)/.test(path)
            ? path.replace(/\/(cn|en|tc)/, `/${lng}`)
            : path.replace(`/${subsidiary}`, `/${subsidiary}/${lng}`)
        );
      } else {
        const [, head = "en", ...rest] = path.split("/");
        replaceHistory(
          `/${
            /^(cn|en|tc)$/.test(head)
              ? lng || head || "en"
              : `${lng || "en"}${head ? `/${head}` : ""}`
          }/${rest.join("/")}`
        );
      }
    },
    [i18n, isSubsidiary, path, replaceHistory, subsidiary]
  );

  useEffect(() => {
    if (/\/en/.test(path)) {
      changeLanguage("en");
    } else if (/\/cn/.test(path)) {
      changeLanguage("cn");
    } else if (/\/tc/.test(path)) {
      changeLanguage("tc");
    } else {
      changeLanguage(/^(cn|en|tc)$/.test(i18n.language) ? i18n.language : "en");
    }
  }, [changeLanguage, i18n.language, path]);

  return (
    <header onMouseLeave={hideDropdown}>
      <Navbar
        expand="lg"
        fixed="top"
        onToggle={(expanded) => {
          if (!expanded) {
            hideDropdown();
          }
        }}
      >
        <div className="container">
          <LinkContainer to={`/${subsidiary}`}>
            <Navbar.Brand>
              <img
                alt="CLSA Premium"
                src="/CLSAPremiumLogo_white.png"
                className="logo"
                height="60"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {isSubsidiary ? (
              <Nav className="mr-auto">
                {subsidiary === "bullion" &&
                  (<LinkContainer to={`/${subsidiary}`}>
                    <Nav.Link>{t("about_us")}</Nav.Link>
                  </LinkContainer>)
                }
                {/*<NavDropdown
                  title={t(subsidiary === "bullion" ? "product" : (subsidiary !== "nz" ? "product_service": '') )}
                  show={show === t(subsidiary === "bullion" ? "product" : "product_service")}
                  onMouseEnter={showDropdown}
                  onToggle={toggleDropdown}
                >
                  {(subsidiary !== "bullion" && subsidiary !== "nz" ) && <LinkContainer to={`/product_service/${subsidiary}/forex`}>
                    <NavDropdown.Item eventKey={2.1}>
                      {t("forex_trading")}
                    </NavDropdown.Item>
              </LinkContainer>}
                  {(subsidiary !== "hk" && subsidiary !== "nz" ) && (
                    <LinkContainer
                      to={`/product_service/${subsidiary}/precious_metals`}
                    >
                      <NavDropdown.Item eventKey={2.2}>
                        {t("precious_metals")}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {(subsidiary !== "hk" && subsidiary !== "bullion"  && subsidiary !== "nz") && (
                    <LinkContainer
                      to={`/product_service/${subsidiary}/commodities`}
                    >
                      <NavDropdown.Item eventKey={2.3}>
                        {t("commodities")}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {(subsidiary !== "hk" && subsidiary !== "bullion" && subsidiary !== "nz") && (
                    <LinkContainer
                      to={`/product_service/${subsidiary}/indices`}
                    >
                      <NavDropdown.Item eventKey={2.4}>
                        {t("indices")}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}
                </NavDropdown>*/}
                {/*(subsidiary !== "bullion" && subsidiary !== "nz") && (
                  <LinkContainer to={`/account_open/${subsidiary}`}>
                    <Nav.Link>{t("account_open")}</Nav.Link>
                  </LinkContainer>
                )*/}
               {/* {(subsidiary !== "bullion" && subsidiary !== "nz") && (
                  <NavDropdown
                    title={t("platform")}
                    show={show === t("platform")}
                    onMouseEnter={showDropdown}
                    onToggle={toggleDropdown}
               >*/}
                   {/* <LinkContainer to={`/platform/mt5/${subsidiary}`}>
                      <NavDropdown.Item eventKey={3.1}>
                        {t("mt5")}
                      </NavDropdown.Item>
                </LinkContainer>*/}
                    {/*<NavDropdown.Item eventKey={3.2} href={`/platform/why_mt5/${subsidiary}`}>{t('why_mt5')}</NavDropdown.Item>
                    <NavDropdown.Item eventKey={3.3} href={`/platform/download_mt5/${subsidiary}`}>{t('download_mt5')}</NavDropdown.Item>*/}
                   {/* {subsidiary === "au" && (
                      <NavDropdown.Item
                        eventKey={3.4}
                        href={`https://client.clsapremium.com/CLSA_CRM_Client/R_Sales/login.php`}
                        target="_blank"
                      >
                        {t("client_portal")}
                      </NavDropdown.Item>
                    )}*/}
              {/*  </NavDropdown>)}*/}
                {subsidiary !== "bullion" && (
                  <LinkContainer to={`/legal_documents/${subsidiary}`}>
                    <Nav.Link>{t("legal_documents")}</Nav.Link>
                  </LinkContainer>
                )}
                {/*(subsidiary !== "bullion" && subsidiary !== "nz") && (
                  <NavDropdown
                    title={t("deposit_withdraw")}
                    show={show === t("deposit_withdraw")}
                    onMouseEnter={showDropdown}
                    onToggle={toggleDropdown}
                  >
                    <LinkContainer to={`/deposit_withdraw/${subsidiary}/deposit`}>
                      <NavDropdown.Item eventKey={4.1}>
                        {t("deposit")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to={`/deposit_withdraw/${subsidiary}/withdrawal`}
                    >
                      <NavDropdown.Item eventKey={4.2}>
                        {t("withdrawal")}
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )*/}
                {(subsidiary !== "bullion" && subsidiary !== "nz") && (
                   <LinkContainer to={`/deposit_withdraw/${subsidiary}/withdrawal`}>
                   <Nav.Link>{t("withdrawal")}</Nav.Link>
                 </LinkContainer>
                )}
                {/*subsidiary !== "bullion" && (
                  <LinkContainer to={`/deposit_withdraw/${subsidiary}/withdrawal`}>
                    <Nav.Link>{t("withdrawal")}</Nav.Link>
                  </LinkContainer>
                )*/}
              </Nav>
            ) : (
              <Nav className="mr-auto">
                <NavDropdown
                  title={t("about_us")}
                  show={show === t("about_us")}
                  onMouseEnter={showDropdown}
                >
                  <LinkContainer to="/about_us/about_clsap">
                    <NavDropdown.Item eventKey={1.1}>
                      {t("tab_about_kvb")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/about_us/board_info">
                    <NavDropdown.Item eventKey={1.2}>
                      {t("board_info")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/about_us/milestone">
                    <NavDropdown.Item eventKey={1.3}>
                      {t("milestone")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  {/* <LinkContainer to="/about_us/accreditation">
                    <NavDropdown.Item eventKey={1.4}>
                      {t("accreditation")}
                    </NavDropdown.Item>
                  </LinkContainer> */}
                  {/*<NavDropdown.Item eventKey={1.5} href='/about_us/news_release'>{t('news_release')}</NavDropdown.Item>*/}
                  <LinkContainer to="/contact">
                    <NavDropdown.Item eventKey={1.6}>
                      {t("contact")}
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <NavDropdown
                  title={t("global_location")}
                  show={show === t("global_location")}
                  onMouseEnter={showDropdown}
                >
                  <LinkContainer to={"/intermediate/au"}>
                    <NavDropdown.Item eventKey={6.2}>
                      {t("australia")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={"/intermediate/nz"}>
                    <NavDropdown.Item eventKey={6.3}>
                      {t("new_zealand")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={"/intermediate/hk"}>
                    <NavDropdown.Item eventKey={6.1}>
                      {t("hong_kong")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={"/intermediate/bullion"}>
                    <NavDropdown.Item eventKey={6.4}>
                      {t("hong_kong", { context: "bullion" })}
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <NavDropdown
                  title={t("investors_relation")}
                  show={show === t("investors_relation")}
                  onMouseEnter={showDropdown}
                >
                  <LinkContainer to="/announcement">
                    <NavDropdown.Item eventKey={5.1}>
                      {t("announcement")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/financial_reports">
                    <NavDropdown.Item eventKey={5.2}>
                      {t("financial_reports")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/prospectus">
                    <NavDropdown.Item eventKey={5.3}>
                      {t("prospectus")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/corporate_governance">
                    <NavDropdown.Item eventKey={5.4}>
                      {t("corporate_governance")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/notifications">
                    <NavDropdown.Item eventKey={5.5}>
                      {t("notifications")}
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </Nav>
            )}
          </Navbar.Collapse>
          <ul>
            <li
              className="lang"
              onClick={() => {
                changeLanguage("en");
              }}
            >
              {t("en")}
            </li>
            <li
              className="lang"
              onClick={() => {
                changeLanguage("cn");
              }}
            >
              {t("cn")}
            </li>
            <li
              className="lang"
              onClick={() => {
                changeLanguage("tc");
              }}
            >
              {t("tc")}
            </li>
          </ul>
        </div>
      </Navbar>
      {show && (
        <div className="curtain">
          <div className="container">
            <div className="wrapper">
              <div className="title">{textHover}</div>
              <div className="brief"></div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
